<template>
  <div>
    <div class="page-back">
      <NavBar class="page-title" :iconColor="'#fff'">
        <div slot="title">团队管理{{ teamInfo.member.level_name }}</div>
      </NavBar>
      <div class="content-layout">
        <div class="grade-info">
          <div class="superior-icon">{{ teamInfo.parent.level_name }}</div>
          <div class="head-portrait-box" v-if="teamInfo.parent.id * 1 > 0">
            <div class="user-info">
              <div class="head-img-box">
                <img :src="teamInfo.parent.head_img" alt="" />
              </div>
              <div class="user-name">{{ teamInfo.parent.nickname }}</div>
            </div>
            <div class="fen-ge-xian" v-if="teamInfo.parent.id * 1 > 0"></div>
            <div class="user-info">
              <div class="head-img-box">
                <img :src="teamInfo.member.head_img" alt="" />
              </div>
              <div class="user-name">{{ teamInfo.member.nickname }}</div>
            </div>
          </div>

          <div class="team-quantity">
            <span>团队人数：</span>
            <span class="number-of">{{ teamInfo.total_num }}人</span>
          </div>
        </div>
        <div>
          <div class="detail-padding">
            <div class="detail-card">
              <div v-if="list.length <= 0" class="prompt-img-box">
                <img
                  src="~@/assets/common/no_evaluation_icon_is_available.png"
                  class="prompt-img"
                />
                <span>当前没有团队</span>
              </div>
              <van-list
                v-model="loading"
                :finished="finished"
                offset="20"
                @load="onLoad"
                v-else
              >
                <van-cell v-for="(item, i_index) in list" :key="i_index">
                  <div class="list-box" @click="setShopSet(item)">
                    <div class="icon-img-box">
                      <img :src="item.head_img" class="icon-img" />
                    </div>
                    <div class="info-user">
                      <div class="level">
                        <span>{{ item.nickname }}</span>
                        <div class="grade">
                          <img
                            v-if="item.type <= 5"
                            :src="require(`@/assets/my/grade-${item.type}.png`)"
                            class="grade-img"
                          />
                        </div>
                      </div>
                      <div class="league-for-time">
                        入团时间：{{ item.created_at }}
                      </div>
                    </div>
                  </div>
                </van-cell>
              </van-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar";
import { getShopTeamInfo, getShopTeamList } from "@/api/shop";
export default {
  name: "group-supervise",
  components: {
    NavBar,
  },
  data() {
    return {
      active: 0, // 分类样式显示
      activeNum: 1, // 余额消费金显示
      currentDate: new Date(),
      finished: false, // 是否加载完成
      loading: false, // 是否开启加载
      list: [],
      page: 1,
      teamInfo: { parent: { id: 0 }, member: { head_img: "" }, total_num: 0 },
      isAjax: false,
      showSell: false,
    };
  },
  mounted() {
    this.getTeamInfo();
    this.getTeamList();
  },
  methods: {
    setShopSet(shop) {
      if (shop.type * 1 === 4) {
        this.$router.push({
          path: "county-supervise",
          query: { setShopId: shop.id },
        });
      } else if (shop.type * 1 === 5) {
        this.$router.push({
          path: "sell-supervise",
          query: { setShopId: shop.id },
        });
      }
    },
    async onLoad() {
      this.getTeamList();
      this.loading = false;
    },
    async getTeamInfo() {
      const res = await getShopTeamInfo();
      if (res.code * 1 == 1) {
        this.teamInfo = res.data;
      }
    },
    async getTeamList() {
      if (this.isAjax) {
        return true;
      }
      this.isAjax = true;
      const res = await getShopTeamList({ type: this.active });
      this.list = [...this.list, ...res.data.items];
      if (this.list.length >= res.data.total_num * 1) {
        this.finished = true;
      }
      ++this.page;
      this.isAjax = false;
    },
    onClass(index) {
      this.active = index;
    },
  },
};
</script>

<style lang="less" scoped>
.page-title {
  color: #fff;
}
.page-back {
  height: 100vh;
  background: url("~@/assets/common/background-img-6@2x.png") no-repeat left top /
    100%;
}
.content-layout {
  padding: 58px 12px 12px 12px;
}
.grade-info {
  position: relative;
  background-color: #fff;
  border-radius: 16px;
  padding: 18px 12px 18px 12px;
  .superior-icon {
    position: absolute;
    top: 0;
    left: 14px;
    width: 26px;
    padding: 10px 0;
    background-color: #fff9ee;
    border-radius: 0 0 26px 26px;
    color: #f6b341;
    writing-mode: vertical-lr;
    text-align: center;
    line-height: 26px;
  }
}
// 头像栏
.head-portrait-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #eaeaea;
  .user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    .head-img-box {
      width: 78px;
      height: 78px;
      margin-bottom: 10px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 78px;
        height: 78px;
      }
    }
  }
  .fen-ge-xian {
    width: 1px;
    height: 66px;
    background-color: #ededed;
  }
}
.team-quantity {
  color: #6f6f6f;
  padding-top: 10px;
  font-size: 16px;
  .number-of {
    color: #fd6d1f;
    font-size: 17px;
  }
}
// 明细
.detail-padding {
  margin-top: 14px;
  .no-record {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    height: 334px;
    background-color: #fff;
    border-radius: 16px;
    margin-top: 12px;
  }
}
// 列表
.detail-card {
  padding: 12px;
  background-color: #fff;
  border-radius: 16px;
  min-height: 61vh;
}
.prompt-img-box {
  padding: 40px 60px 140px 60px;
  text-align: center;
}
.prompt-img {
  width: 220px;
  display: block;
  margin-bottom: 10px;
}
.van-cell {
  padding: 0;
}
.list-box {
  display: flex;
  align-items: center;
  padding: 12px 0;
  .icon-img-box {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    .icon-img {
      display: block;
      width: 50px;
      height: 50px;
    }
  }
  .info-user {
    font-size: 16px;
    padding-left: 10px;
    .level {
      display: flex;
      align-items: center;
      font-size: 17px;
      .grade {
        margin-left: 10px;
        width: 58px;
        .grade-img {
          width: 100%;
        }
      }
    }
    .league-for-time {
      font-size: 13px;
      color: #6f6f6f;
    }
  }
}
.user-name {
  font-size: 17px;
}
</style>
